import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatDate } from "utils/dates";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import moment from "moment";

import {
  Layout,
  TextStyle,
  Card,
  Button,
  TextContainer,
  ButtonGroup,
  ChoiceList,
  Stack,
  Banner,
  FormLayout,
  Select,
  Tooltip,
  TextField,
  Scrollable,
  Spinner,
  ResourceList,
  ResourceItem,
  Text,
  Inline,
  Box,
  AlphaStack,
  DataTable,
} from "@shopify/polaris";
import formatRow from "utils/formatRow";
import Modal from "components/Modal/Modal";
import {
  editUser,
  fetchCompanyDefaultCurrency,
  fetchCompanyDefaultLanguage,
  switchCompanyDefaultCurrency,
  switchCompanyDefaultLanguage,
} from "redux/features/user/userSlice";
import {
  deleteSandboxData,
  fetchCurrentPlan,
  switchCalculateRealTime,
  switchCalculateThresholds,
  switchCalculateThresholdsNexus,
  switchSandboxMode,
} from "redux/features/subscription/subscriptionSlice";
import {
  createServiceProvided,
  deleteServiceProvided,
  editServiceProvided,
  fetchServicesPvd,
} from "redux/features/other/otherSlice";
import editIMG from "img/settings.svg";
import deleteIMG from "img/delete.svg";
import { getCurrencySymbol } from "utils/currency";
import { formatMoney } from "utils/numbers";
import { createCustomDispatch } from "helpers/customDispatch";

import "./index.scss";
import StripePayment from "containers/StripePayment/StripePayment";

const SandboxStatus = Object.freeze({
  on: "SandBox on",
  off: "SandBox off",
});

class ProfileMembership extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      sandboxValue:
        this.props &&
        this.props.currentPlan &&
        this.props.currentPlan.sandbox.is_sandbox
          ? SandboxStatus.on
          : SandboxStatus.off,
      currency: this.props.defaultCurrency
        ? this.props.defaultCurrency.currency_code
        : "eur",
      sandboxData: {
        is_vat_return: false,
        is_vat_registration: false,
        is_websites: false,
        is_transactions: false,
      },
      
      actionDialogs: {
        switchSandbox: { open: false },
        deleteService: { open: false },
        editService: { open: false },
      },
      
      paymentEdit: false,
      serviceEdit: "",
      descEdit: "",
      countryAdd: "",
      serviceAdd: "",
      descAdd: "",
    };
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.currentPlan !== this.props.currentPlan) {
      this.setState({
        sandboxValue:
          this.props.currentPlan && this.props.currentPlan.sandbox.is_sandbox
            ? SandboxStatus.on
            : SandboxStatus.off,
      });
    }
    
    if (prevProps.defaultCurrency !== this.props.defaultCurrency) {
      this.setState({
        currency: this.props.defaultCurrency
          ? this.props.defaultCurrency.currency_code
          : "eur",
      });
    }
  }
  
  getAllCountriesForSelect = () => {
    const optionsCountries = [
      {
        key: "",
        label: "",
        value: "",
      },
    ];
    this.props.countries.map((country) => {
      optionsCountries.push({
        key: country.code,
        label: country.name_no_article,
        value: country.code,
      });
    });
    return optionsCountries;
  };
  
  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };
  
  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };
  
  handleSwitchSandboxMode = (deletingOptions) => {
    const { sandboxData } = this.state;
    if (deletingOptions) {
      this.props.deleteSandboxData({ data: sandboxData });
    }
    
    this.props
      .switchSandboxMode()
      .then((r) => {
        this.setState({ sandboxValue: r });
        this.props.fetchCurrentPlan();
        this.handleActionDialogsClose("switchSandbox");
      })
      .catch(_.noop);
  };
  
  doDeleteServicePvd = () => {
    const { actionDialogs } = this.state;
    const id = _.get(actionDialogs, "cellData.id");
    
    if (id) {
      this.props
        .deleteServicePvd(id)
        .then(() => {
          this.props.fetchServicesPvd();
          this.handleActionDialogsClose("deleteService");
        })
        .catch(_.noop);
    }
  };
  
  doEditServicePvd = () => {
    const { actionDialogs, serviceEdit, descEdit, countryEdit } = this.state;
    
    const data = {
      id: actionDialogs.cellData.id,
      service_id: serviceEdit || actionDialogs.cellData.service_id,
      country_id: countryEdit || actionDialogs.cellData.country_id,
      description: descEdit,
    };
    
    this.props
      .editServicePvd(data)
      .then(() => {
        this.props.fetchServicesPvd();
        this.handleActionDialogsClose("editService");
      })
      .catch(_.noop);
  };
  
  switchDefaultCurrency = (currency) => {
    const formData = {
      currency_code: currency,
    };
    this.props
      .switchCompanyDefaultCurrency(formData)
      .then(() => {
        window.location.reload(false);
      })
      .catch(_.noop);
  };
  
  doCreateServicePvd = (countryAdd, serviceAdd, descAdd) => {
    const formData = {
      country_id: countryAdd,
      service_id: +serviceAdd,
      description: descAdd,
    };
    
    this.props
      .createServicePvd(formData)
      .then(() => {
        this.props.fetchServicesPvd();
        this.setState({
          countryAdd: "",
          serviceAdd: "",
          descAdd: "",
        });
      })
      .catch(_.noop);
  };
  
  renderSandboxDialog() {
    const { t, user, currentPlan } = this.props;
    const { actionDialogs, sandboxData } = this.state;
    
    const isActive =
      actionDialogs.cellData &&
      actionDialogs.cellData.sandbox_mode_on &&
      currentPlan.sandbox.is_sandbox;
    
    const onClose = () => this.handleActionDialogsClose("switchSandbox");
    
    return (
      <Modal
        title={
          isActive
            ? `${t("profileMemb.turnOffSB")}?`
            : `${t("profileMemb.turnOnSB")}?`
        }
        iconType={"warning"}
        description={isActive ? t("profileMemb.markSBData") : ""}
        onClose={onClose}
        visible={actionDialogs.switchSandbox.open}
        content={
          <>
            {isActive && (
              <Stack vertical>
                <Stack>
                  <ChoiceList
                    allowMultiple
                    /* title={profileMemb.markSBData}*/
                    choices={[
                      {
                        label: "Tax Returns",
                        value: "is_vat_return",
                      },
                      {
                        label: t("profileMemb.trans"),
                        value: "is_transactions",
                      },
                      {
                        label: user.is_omp
                          ? "Projects"
                          : t("profileMemb.stores"),
                        value: "is_websites",
                      },
                      {
                        label: "Tax Settings",
                        value: "is_vat_registration",
                      },
                    ]}
                    selected={Object.keys(sandboxData).filter(
                      (key) => sandboxData[key]
                    )}
                    onChange={(sandboxData) =>
                      this.setState({
                        sandboxData: {
                          is_vat_return: sandboxData.includes("is_vat_return"),
                          is_transactions:
                            sandboxData.includes("is_transactions"),
                          is_websites: sandboxData.includes("is_websites"),
                          is_vat_registration: sandboxData.includes(
                            "is_vat_registration"
                          ),
                        },
                      })
                    }
                  />
                </Stack>
              </Stack>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("vatReg.cancel")}
            </Button>
            <Button
              primary
              size='large'
              onClick={() => {
                this.handleSwitchSandboxMode(isActive);
              }}
            >
              {isActive
                ? t("profileMemb.turnOffSB")
                : t("profileMemb.turnOnSB")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }
  
  renderDeleteServiceDialog = () => {
    const { t } = this.props;
    const { actionDialogs } = this.state;
    
    const onClose = () => this.handleActionDialogsClose("deleteService");
    
    return (
      <Modal
        title={t("profileMemb.delete")}
        iconType={"danger"}
        visible={actionDialogs.deleteService.open}
        description={
          <>
            {actionDialogs.cellData && (
              <p>
                Are you sure you want to delete the service "
                {actionDialogs.cellData.service}" for{" "}
                {actionDialogs.cellData.country}?
              </p>
            )}
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button size='large' onClick={onClose}>
              {t("vatReg.cancel")}
            </Button>
            <Button size='large' destructive onClick={this.doDeleteServicePvd}>
              {t("profileMemb.delete")}
            </Button>
          </ButtonGroup>
        }
        onClose={onClose}
      />
    );
  };
  
  renderEditServiceDialog = () => {
    const { t } = this.props;
    const { actionDialogs, serviceEdit, countryEdit, descEdit } = this.state;
    let descTextField = false;
    
    if (actionDialogs.cellData) {
      descTextField = (
        <TextField
          label='Description'
          value={
            descEdit || descEdit === ""
              ? descEdit
              : actionDialogs.cellData.description || ""
          }
          onChange={(value) => this.setState({ descEdit: value })}
        />
      );
    }
    
    return (
      <Modal
        title={t("profileMemb.edit")}
        visible={actionDialogs.editService.open}
        onClose={() => {
          this.handleActionDialogsClose("editService");
          this.setState({
            serviceEdit: "",
            descEdit: "",
            countryEdit: "",
          });
        }}
        content={
          <>
            {actionDialogs.cellData && (
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    id='cntr_slct'
                    label={"Country"}
                    options={this.props.countries.map((country) => ({
                      key: country.code,
                      label: country.name_no_article,
                      value: country.code,
                    }))}
                    onChange={(country) =>
                      this.setState({ countryEdit: country })
                    }
                    value={countryEdit || actionDialogs.cellData.country_id}
                  />
                  <Select
                    id='srv_slct'
                    label={"Service"}
                    options={this.props.typesServicesPvd.map((service) => ({
                      label: service.name,
                      value: service.id.toString(),
                      key: `${service.id}serv`,
                    }))}
                    onChange={(value) => this.setState({ serviceEdit: value })}
                    value={
                      serviceEdit ||
                      actionDialogs?.cellData?.service_id?.toString() ||
                      ""
                    }
                  />
                </FormLayout.Group>
                {serviceEdit === "7" && descTextField}
                {!serviceEdit &&
                  actionDialogs.cellData.service_id === 7 &&
                  descTextField}
              </FormLayout>
            )}
          </>
        }
        footer={
          <Button
            disabled={!countryEdit && !descEdit && !serviceEdit}
            primary
            onClick={this.doEditServicePvd}
          >
            {"Save"}
          </Button>
        }
      />
    );
  };
  
  renderSandboxSection = (sandbox) => {
    const { t } = this.props;
    return (
      <Card.Section>
        <TextContainer>
          <p>
            {t("profileMemb.trialModeAv")}: {formatDate(sandbox.date)}
          </p>
        </TextContainer>
        <br />
        <ChoiceList
          title={t("profileMemb.sbMode")}
          choices={[
            { label: t("profileMemb.on"), value: SandboxStatus.on },
            { label: t("profileMemb.off"), value: SandboxStatus.off },
          ]}
          selected={this.state.sandboxValue}
          onChange={() =>
            this.handleActionDialogsOpen("switchSandbox", {
              sandbox_mode_on: sandbox.is_sandbox,
            })
          }
        />
        <br />
      </Card.Section>
    );
  };
  
  renderPlanInfo = (type, plan, packages) => {
    const { t, currentPlan } = this.props;
    
    return (
      <TextContainer>
        <p>
          <TextStyle variation='strong'>
            {t("profileMemb.tariffPlan")} {type === "vat" ? "vat" : "sales tax"}
            : {plan.tariff_plan.name}
          </TextStyle>
        </p>

        <div>
          {t("profileMemb.membIs")}
          {getCurrencySymbol(currentPlan.currency_code)}
          {type === "vat" ? currentPlan.sum : currentPlan.sum_sales_tax}/
          {t("profileMemb.month")}
        </div>
        
        {packages.map((item, ind) =>
          item ? <div key={`${ind}p`}>{item}</div> : ""
        )}
        
        <div>
          {currentPlan.is_active
            ? `${t("profileMemb.nextBillDate")}: ${formatDate(
              type === "vat"
                ? currentPlan.your_next_billing_date
                : currentPlan.your_next_billing_date_sales_tax
            )}`
            : `${t("profileMemb.membUntil")} ${formatDate(
              type === "vat"
                ? moment(currentPlan.your_next_billing_date).subtract(
                  1,
                  "days"
                )
                : moment(
                  currentPlan.your_next_billing_date_sales_tax
                ).subtract(1, "days")
            )}`}
        </div>
      </TextContainer>
    );
  };
  
  renderActivePlan = () => {
    const { t, user, currentPlan } = this.props;
    
    const isPaidSubs = {
      vat: currentPlan.subscriptions && currentPlan.sum,
      salesTax:
        currentPlan.subscriptions_sales_tax && currentPlan.sum_sales_tax,
      epr: currentPlan.subscriptions_epr && currentPlan.sum,
      omp: currentPlan.subscriptions_omp && currentPlan.sum_omp,
    };
    
    return (
      <>
        {(currentPlan.subscriptions && !currentPlan.is_active_omp) && (
          <Card.Section>
            <Inline align='space-between'>
              <Box>
                <Text variant='headingLg' as='p'>
                  VAT subscription
                </Text>
                <div className='subscription-card'>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.subscriptions.tariff_plan.name} plan:
                    </Text>
                    <Text color='subdued'>
                      {getCurrencySymbol(currentPlan.currency_code)}
                      {formatMoney(currentPlan.sum)} / {t("profileMemb.month")}
                    </Text>
                  </Inline>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>Additional country:</Text>
                    <Text color='subdued'>
                      {currentPlan.subscriptions.countries?.length || 0}
                    </Text>
                  </Inline>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.is_active
                        ? t("profileMemb.nextBillDate") + ":"
                        : t("profileMemb.membUntil") + ":"}
                    </Text>
                    <Text color='subdued'>
                      {currentPlan.is_active
                        ? formatDate(currentPlan.your_next_billing_date)
                        : formatDate(
                          moment(currentPlan.your_next_billing_date).subtract(
                            1,
                            "days"
                          )
                        )}
                    </Text>
                  </Inline>
                </div>
              </Box>
              {this.renderActivePlanButtons({
                isActive: currentPlan.is_active,
                isPaidSubs: isPaidSubs.vat,
                tabIndex: 0,
              })}
            </Inline>
          </Card.Section>
        )}
        {(currentPlan.subscriptions_sales_tax && !currentPlan.is_active_omp) && (
          <Card.Section>
            <Inline align='space-between'>
              <Box>
                <Text variant='headingLg' as='p'>
                  Sales Tax subscription
                </Text>
                <div className='subscription-card'>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.subscriptions_sales_tax.tariff_plan.name}{" "}
                      plan:
                    </Text>
                    <Text color='subdued'>
                      {getCurrencySymbol(currentPlan.currency_code)}
                      {formatMoney(currentPlan.sum_sales_tax)} /{" "}
                      {t("profileMemb.month")}
                    </Text>
                  </Inline>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.is_active_sales_tax
                        ? t("profileMemb.nextBillDate") + ":"
                        : t("profileMemb.membUntil") + ":"}
                    </Text>
                    <Text color='subdued'>
                      {currentPlan.is_active_sales_tax
                        ? formatDate(
                          currentPlan.your_next_billing_date_sales_tax
                        )
                        : formatDate(
                          moment(
                            currentPlan.your_next_billing_date_sales_tax
                          ).subtract(1, "days")
                        )}
                    </Text>
                  </Inline>
                </div>
              </Box>
              {this.renderActivePlanButtons({
                isActive: currentPlan.is_active_sales_tax,
                isPaidSubs: isPaidSubs.salesTax,
                tabIndex: 1,
              })}
              {!currentPlan.is_active_sales_tax && !user.is_active_omp && (
                <Button
                  primary
                  onClick={() => this.props.navigate("/subscription")}
                >
                  {t("profileMemb.renewNow")}
                </Button>
              )}
            </Inline>
          </Card.Section>
        )
        }
        
        {(currentPlan.subscriptions_epr && !currentPlan.is_active_omp) && (
          <Card.Section>
            <Inline align='space-between'>
              <Box>
                <Text variant='headingLg' as='p'>
                  EPR subscription
                </Text>
                <div className='subscription-card'>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.subscriptions_epr.name}:
                    </Text>
                    <Text color='subdued'>
                      {getCurrencySymbol(currentPlan.currency_code)}
                      {formatMoney(currentPlan.subscriptions_epr.sum)} /{" "}
                      {t("profileMemb.annually")}
                    </Text>
                  </Inline>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.is_active_epr
                        ? t("profileMemb.nextBillDate") + ":"
                        : t("profileMemb.membUntil") + ":"}
                    </Text>
                    <Text color='subdued'>
                      {formatDate(currentPlan.your_next_billing_date_epr)}
                    </Text>
                  </Inline>
                </div>
              </Box>
              {this.renderActivePlanButtons({
                isActive: currentPlan.is_active_epr,
                isPaidSubs: isPaidSubs.epr,
                tabIndex: 3,
              })}
            </Inline>
          </Card.Section>
        )}
        {(currentPlan.subscriptions_omp && currentPlan.is_active_omp) && (
          <Card.Section>
            <Inline align='space-between'>
              <Box>
                <Text variant='headingLg' as='p'>
                  OMP subscription
                </Text>
                <div className='subscription-card'>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.subscriptions_omp.tariff_plan.name} plan:
                    </Text>
                    <Text color='subdued'>
                      {getCurrencySymbol(currentPlan.currency_code)}
                      {formatMoney(currentPlan.sum_omp)} /{" "}
                      {t("profileMemb.month")}
                    </Text>
                  </Inline>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>Additional country:</Text>
                    <Text color='subdued'>
                      {currentPlan.subscriptions_omp.countries?.length || 0}
                    </Text>
                  </Inline>
                  <Inline spacing='1' align='start'>
                    <Text fontWeight='medium'>
                      {currentPlan.is_active
                        ? t("profileMemb.nextBillDate") + ":"
                        : t("profileMemb.membUntil") + ":"}
                    </Text>
                    <Text color='subdued'>
                      {currentPlan.is_active
                        ? formatDate(currentPlan.your_next_billing_date_omp)
                        : formatDate(
                          moment(
                            currentPlan.your_next_billing_date_omp
                          ).subtract(1, "days")
                        )}
                    </Text>
                  </Inline>
                </div>
              </Box>
              {this.renderActivePlanButtons({
                isActive: currentPlan.is_active_omp,
                isPaidSubs: isPaidSubs.omp,
                tabIndex: 2,
              })}
            </Inline>
          </Card.Section>
        )}
      </>
    );
  };
  
  renderActivePlanButtons = (options) => {
    const { t } = this.props;
    const { isActive, isAddons = true, tabIndex } = options;
    return (
      isActive && (
        <AlphaStack align='center'>
          <Button
            primary
            fullWidth
            onClick={() => this.props.navigate("/subscription")}
          >
            {t("profileMemb.changeSub")}
          </Button>
            <>
              {isAddons && (
                <Button
                  fullWidth
                  onClick={() =>
                    this.props.navigate("/addons", { state: { tabIndex } })
                  }
                >
                  {t("profileMemb.buyAddons")}
                </Button>
              )}
            </>
        </AlphaStack>
      )
    );
  };
  
  renderAddService() {
    const { creatingServicePvdError } = this.props;
    const { countryAdd, serviceAdd, descAdd } = this.state;
    
    return (
      <Card title={"Add country with service"} sectioned>
        {creatingServicePvdError && (
          <div>
            <Banner status='critical'>{creatingServicePvdError}</Banner>
            <br />
          </div>
        )}
        <FormLayout>
          <FormLayout.Group>
            <Select
              id='cntr_slct'
              label={"Country"}
              options={this.getAllCountriesForSelect()}
              onChange={(country) => this.setState({ countryAdd: country })}
              value={countryAdd}
            />
            <Select
              id='srv_slct'
              label={"Service"}
              options={[
                { label: "", value: "", key: `0serv` },
                ...this.props.typesServicesPvd.map((service) => ({
                  label: service.name,
                  value: `${service.id}`,
                  key: `${service.id}serv`,
                })),
              ]}
              onChange={(value) => this.setState({ serviceAdd: value })}
              value={serviceAdd}
            />
          </FormLayout.Group>
          {serviceAdd === "7" && (
            <TextField
              label='Description'
              value={descAdd}
              onChange={(value) => this.setState({ descAdd: value })}
            />
          )}
        </FormLayout>
        <br />
        <ButtonGroup>
          <Button
            disabled={!countryAdd || !serviceAdd}
            primary
            onClick={() =>
              this.doCreateServicePvd(countryAdd, serviceAdd, descAdd)
            }
          >
            &nbsp;&nbsp;Add&nbsp;&nbsp;
          </Button>
          <Button
            onClick={() =>
              this.setState({
                isOpenAddService: false,
                countryAdd: "",
                serviceAdd: "",
                descAdd: "",
              })
            }
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Card>
    );
  }
  
  renderTaxAgentClients = () => {
    const { paymentInfo } = this.props;
    return (
      <Card title={"Clients"}>
        <br />
        <Scrollable shadow className='Scrollable'>
          {!_.isEmpty(paymentInfo) ? (
            <ResourceList
              items={paymentInfo}
              renderItem={(item, index) => (
                <ResourceItem
                  key={`${index}-row`}
                  id={index}
                  accessibilityLabel={item.company}
                >
                  <h3>
                    <TextStyle variation='strong'>{item.company}</TextStyle>
                  </h3>
                  <div>Subscription Plan: {item.subs}</div>
                  {item.next_billing_date && (
                    <div>{formatDate(item.next_billing_date)}</div>
                  )}
                </ResourceItem>
              )}
            />
          ) : (
            <p style={{ padding: "2rem " }}>You don"t have any client.</p>
          )}
        </Scrollable>
      </Card>
    );
  };
  
  renderTaxAgentServices = () => {
    const { t } = this.props;
    const rowsServices = this.props.servicesPvd;
    
    const columns = [
      {
        property: "country",
        header: {
          label: "Country",
        },
      },
      {
        property: "service",
        header: {
          label: "Service",
        },
      },
      {
        property: "description",
        header: {
          label: "Description",
        },
        cell: {
          formatters: [(value) => value || "-"],
        },
      },
      {
        property: "services_id",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip content={t("profileMemb.edit")}>
                  <Button
                    plain
                    onClick={() =>
                      this.handleActionDialogsOpen("editService", rowData)
                    }
                  >
                    <img src={editIMG} alt='edit' />
                  </Button>
                </Tooltip>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Tooltip content={t("profileMemb.delete")}>
                  <Button
                    plain
                    onClick={() =>
                      this.handleActionDialogsOpen("deleteService", rowData)
                    }
                  >
                    <img src={deleteIMG} alt={t("profileMemb.delete")} />
                  </Button>
                </Tooltip>
              </span>
            ),
          ],
        },
      },
    ];
    
    const rows = formatRow(rowsServices, columns);
    
    return (
      <Card
        title='List of services provided'
        actions={[
          {
            content: "Add country with service",
            onAction: () => this.setState({ isOpenAddService: true }),
          },
        ]}
      >
        <br />
        <DataTable
          columnContentTypes={["text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
          hideScrollIndicator
        />
      </Card>
    );
  };
  
  render() {
    const {
      t,
      currentPlan,
      user,
      paymentInfo,
      fetchingServicesPvd,
      fetchingPaymentInfo,
    } = this.props;
    const { currency, paymentEdit, isOpenAddService } = this.state;
    
    const description = (
      <div>
        {user.company && (
          <p>
            <TextStyle variation='subdued'>
              {t("profileMemb.thanks1")} {formatDate(user.company.created_at)}{" "}
              {t("profileMemb.thanks2")}
            </TextStyle>
          </p>
        )}
        {!user.is_omp && (
          <p>
            <TextStyle variation='subdued'>
              <Link className='Polaris-Link' to='/subscription'>
                {t("profileMemb.compPlan1")}
              </Link>{" "}
              {t("profileMemb.compPlan2")}
            </TextStyle>
          </p>
        )}
      </div>
    );
    
    return (
      <>
        {_.isEmpty(user) ? (
          <Spinner />
        ) : (
          <Layout.AnnotatedSection
            title={
              <Text variant='headingLg' as='p'>
                {user.company.id === user.tax_agent_company_id ||
                (user.is_manager && user.company.is_tax_agent)
                  ? "Membership details by client"
                  : t("profileMemb.membDetails")}
              </Text>
            }
            description={
              user.company.id !== user.tax_agent_company_id && description
            }
          >
            <Layout.Section>
              {!fetchingPaymentInfo &&
                !_.isEmpty(paymentInfo) &&
                (user.company.id === user.tax_agent_company_id ||
                  (user.is_manager && user.company.is_tax_agent)) &&
                this.renderTaxAgentClients()}
              
              {fetchingPaymentInfo && (
                <Card sectioned>
                  <Spinner />
                </Card>
              )}
              
              {!fetchingServicesPvd &&
                user.company.id === user.tax_agent_company_id &&
                this.renderTaxAgentServices()}
              {fetchingServicesPvd && (
                <Card sectioned>
                  <Spinner />
                </Card>
              )}
              
              {user.company.id === user.tax_agent_company_id &&
                isOpenAddService &&
                this.renderAddService()}
              
              {currentPlan &&
                user.company.id !== user.tax_agent_company_id &&
                !(user.is_manager && user.company.is_tax_agent) && (
                  <Card>
                    {currentPlan.sandbox &&
                      currentPlan.sandbox.is_active &&
                      this.renderSandboxSection(currentPlan.sandbox)}
                    
                    {(currentPlan.is_active ||
                        currentPlan.is_active_sales_tax) &&
                      this.renderActivePlan()}
                  </Card>
                )}
              
              {!currentPlan &&
                user.company.id !== user.tax_agent_company_id &&
                !(user.is_manager && user.company.is_tax_agent) && (
                  <Card
                    title={
                      <Text variant='headingLg' as='p'>
                        {t("profileMemb.planDetails")}
                      </Text>
                    }
                    sectioned
                  >
                    <Button
                      primary
                      onClick={() => this.props.navigate("/subscription")}
                    >
                      {t("profileMemb.renewNow")}
                    </Button>
                  </Card>
                )}
              
              <Card
                title={
                  <Text variant='headingLg' as='p'>
                    {t("profileMemb.paymentMethod")}
                  </Text>
                }
                sectioned
              >
                {!paymentEdit && (
                  <div>
                    {user.company && user.company.info.card && (
                      <>
                        <div className='card'>
                          <Text variant='bodyLg'>
                            {user.company.info.card.number}
                          </Text>
                          <Text>
                            {t("profileMemb.exp")}: {user.company.info.card.exp}
                          </Text>
                          <Text>Type: {user.company.info.card.type}</Text>
                        </div>
                        <br />
                      </>
                    )}
                    <StripePayment service={"change"} />
                  </div>
                )}
                
                {paymentEdit && (
                  <div>
                    <p>{t("profileMemb.payMethodInFuture")}</p>
                    <br />
                    <Button
                      onClick={() => this.setState({ paymentEdit: false })}
                    >
                      {t("profileMemb.back")}
                    </Button>
                  </div>
                )}
                <br />
                <ChoiceList
                  title={
                    <TextStyle variation='strong'>
                      {t("profileMemb.priorityCurrency")}
                    </TextStyle>
                  }
                  choices={[
                    { label: t("profileMemb.eur"), value: "eur" },
                    { label: t("profileMemb.usd"), value: "usd" },
                    { label: t("profileMemb.gbp"), value: "gbp" },
                  ]}
                  selected={currency}
                  onChange={(arr) => this.switchDefaultCurrency(arr[0])}
                />
              </Card>
              
              {this.renderSandboxDialog()}
              {this.renderDeleteServiceDialog()}
              {this.renderEditServiceDialog()}
            </Layout.Section>
          </Layout.AnnotatedSection>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  currentPlan: state.subscription.currentPlan,
  defaultCurrency: state.user.defaultCurrency,
  defaultLanguage: state.user.defaultLanguage,
  paymentInfo: state.profile.paymentInfo,
  fetchingPaymentInfo: state.profile.fetchingPaymentInfo,
  countries: state.other.countriesAll,
  typesServicesPvd: state.other.typesServicesPvd,
  servicesPvd: state.other.servicesPvd,
  fetchingServicesPvd: state.other.fetchingServicesPvd,
  creatingServicePvdError: state.other.creatingServicePvdError,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);
  
  return {
    editUser: () => dispatch(editUser()),
    switchSandboxMode: () => dispatch(switchSandboxMode()),
    deleteSandboxData: (params) => dispatch(deleteSandboxData(params)),
    fetchCurrentPlan: () => dispatch(fetchCurrentPlan()),
    switchCalculateThresholds: () => dispatch(switchCalculateThresholds()),
    switchCalculateThresholdsNexus: () =>
      dispatch(switchCalculateThresholdsNexus()),
    switchCalculateRealTime: () => dispatch(switchCalculateRealTime()),
    switchCompanyDefaultCurrency: (params) =>
      dispatch(switchCompanyDefaultCurrency(params)),
    fetchCompanyDefaultCurrency: () => dispatch(fetchCompanyDefaultCurrency()),
    switchCompanyDefaultLanguage: (params) =>
      dispatch(switchCompanyDefaultLanguage(params)),
    fetchCompanyDefaultLanguage: () => dispatch(fetchCompanyDefaultLanguage()),
    fetchServicesPvd: () => dispatch(fetchServicesPvd()),
    createServicePvd: (params) => dispatch(createServiceProvided(params)),
    deleteServicePvd: (params) => dispatch(deleteServiceProvided(params)),
    editServicePvd: (params) => dispatch(editServiceProvided(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileMembership))
);
